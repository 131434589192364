import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';

@Component({
  selector: 'complete-your-order',
  templateUrl: './complete-your-order.html',
  styleUrls: ['../../../user/user-view/user-home/user-home.scss'],
})
export class CompleteYourOrderComponent implements OnInit {
    @Input('productsInCart') productsInCart:any;
    @Input('cartDiscount') cartDiscount:number;
    @Input('totalSp') totalSp:number;
    @Input('levelFourBanners') levelFourBanners: boolean;
    @Input('userDrImage') userDrImage: any;
    @Output('goToCart') goToCart:EventEmitter<void> = new EventEmitter();
    ngOnInit(): void {}
    handleClick():void {
      this.goToCart.emit();
    }
}
