<section *ngIf="levelFourBanners" class="tw-bg-blue-100 tw-pb-2 tw-p-5 tw-shadow-[0_2px_4px_0_rgba(0,0,0,0.10)] tw-mx-4  tw-shadow-md tw-rounded-xl tw-overflow-hidden tw-mb-6 tw-mt-4" style="background: white !important">
    <div class="tw-flex tw-gap-2 tw-mb-4">
      <span>
        <img alt="icon" src="/assets/icons/recommended.gif" class="tw-w-[48px] tw-h-[48px] tw-rounded-full" id="tick">
      </span>
      <span>
          <h2 class="tw-font-body tw-font-bold tw-text-500 tw-text-gray-800 tw-tracking-normal" id="suggested-title">
            Suggested for you
          </h2>
          <p class="tw-font-body tw-text-300 tw-text-gray-500" id="suggested-subtitle">
            Basis your skin goal
          </p>
      </span>
    </div>
    <div id="recommended-item" class="tw-flex tw-bg-white tw-rounded-2 tw-border-2 tw-border-gray-100 tw-mb-4 tw-relative tw-overflow-hidden" (click)="handleClick()">
          <div class="tw-flex-none tw-flex tw-justify-center tw-items-center tw-box-content tw-w-2/5">
            <img alt="product" class="tw-h-[144px] tw-max-w-full tw-max-h-full tw-min-w-full tw-min-h-full" [src]="recommendedItems[0]?.rebrandedImageWithBackground || recommendedItems[0]?.productUnboxedImage || recommendedItems[0]?.rebrandedImageWithoutBackground" />
          </div>
          <div class="tw-px-4 tw-flex tw-flex-col tw-items-start tw-relative tw-pb-2 tw-pt-5">
            <p id="recommended-item-title" class="tw-font-body tw-text-300 tw-text-gray-800 tw-tracking-normal tw-mb-2">
                {{recommendedItems[0]?.title}}
            </p>
            <p class="tw-font-body tw-text-300 tw-tracking-normal">
              <span class="tw-relative tw-inline-block">
              <span class="tw-relative z-10 tw-text-gray-400">&ngsp;&ngsp; ₹{{recommendedItems[0]?.mrp}}&ngsp;</span>
                <span class="tw-absolute tw-top-1/2 tw-left-0 tw-right-0 tw-h-[1px] tw-bg-gray-400 tw-w-[90%] tw-mx-auto"></span>
              </span>
              <span class="tw-font-bold tw-text-orange-400">&nbsp;₹{{recommendedItems[0]?.price}}</span>
            </p>
        </div>
        <div class="tw-absolute tw-bottom-0 tw-left-0 tw-w-full tw-text-center tw-gap-2 tw-bg-teal-100 tw-flex tw-items-center tw-justify-center">
          <cs-icon class="tw-h-3 tw-w-3 tw-text-bold tw-text-teal-400" [iconName]='"done"'></cs-icon>
              <p class="tw-font-body tw-text-teal-500 tw-text-100 tw-whitespace-nowrap">
                Highly Recommended
              </p>
        </div>
    </div>
    <div id="buyNowBtn" class="tw-w-full tw-mb-2 tw-shadow-[0_2px_4px_0_rgba(0,0,0,0.10)] tw-rounded-[12px]" (click)="handleClick()">
      <div class="tw-relative tw-flex tw-h-[40px] tw-px-[24px] tw-rounded-[12px] tw-bg-teal-600 tw-py-[8px] tw-justify-center tw-items-center tw-gap-[8px] tw-text-white tw-group">
        <div class="shine loop tw-absolute tw-inset-0 tw-bg-gradient-to-r tw-from-transparent tw-via-white/50 tw-to-transparent tw-pointer-events-none"></div>
        <span class="tw-font-body tw-font-bold tw-lowercase tw-text-white tw-text-300 tw-tracking-normal tw-normal-case">
          <span i18n="@@buyNow">Buy Now</span>
        </span>
      </div>
    </div>
  </section>