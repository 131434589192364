<ng-container *ngIf="isRebrandingV1; then rebrandedTemplateV1 else originalTemplate"></ng-container>

<ng-template #originalTemplate>
  <!-- Original template -->
  <div class="tw-bg-gray-100">
    <section class="tw-bg-purple-200 tw-pt-5 tw-bg-grid-layout tw-bg-repeat-x tw-flex tw-gap-4 tw-items-center tw-px-6 tw-pb-5" style="background-position: bottom -75px right 0" *ngIf="!showSkinReport">
      <div class="tw-flex-none tw-relative tw-h-[148px] tw-w-[148px]">
        <svg class="tw-absolute tw-left-0" width="148" height="148" viewBox="0 0 148 148" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle class="tw-stroke-[#D9D9D9]" cx="50%" cy="50%" r="70px" stroke-width="8" fill="none"></circle>
        </svg>
        <svg class="tw-absolute tw-left-0" width="148" height="148" viewBox="0 0 148 148" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle class="tw-stroke-yellow-400 from_0" cx="50%" cy="50%" r="70px" stroke-width="8" fill="none" stroke-dasharray="440px, 440px" [ngStyle]="{ 'animation-delay': elapsedTime + 'ms', 'animation-duration': defaultTime + 'ms'}"></circle>
        </svg>
        <div class="tw-flex tw-absolute tw-top-[60px] tw-left-[30px]">
          <div class="tw-flex-none">
            <p class="tw-font-body tw-font-bold tw-text-900 tw-leading-[28px]"> 0{{timer.minutes}}
            </p>
            <p class="tw-font-body tw-text-300 tw-text-center">min</p>
          </div>
          <div class="tw-flex-none tw-font-body tw-font-bold tw-text-900 tw-leading-[28px]"> : </div>
          <div class="tw-flex-none">
            <p class="tw-font-body tw-font-bold tw-text-900 tw-leading-[28px]">
              {{timer.seconds < 10 ? '0' + timer.seconds : timer.seconds}}
            </p>
            <p class="tw-font-body tw-text-300 tw-text-center">sec</p>
          </div>
        </div>
      </div>
      <div class="tw-flex-1" *ngIf="!showSkinReport">
        <h1 class="tw-pb-2 tw-font-body tw-font-bold tw-text-400 text tw-text-gray-900" i18n="@@pleaseWaitForTwoMins"> Please wait for a couple of minutes </h1>
        <p class="tw-font-body tw-text-300" i18n="@@effTreatmentPrep"> Based on your analysis, we are preparing the most effective treatment kit for you. </p>
      </div>
      <div class="tw-flex-1" *ngIf="showSkinReport && !showRegimenToast">
        <h1 class="tw-pb-2 tw-font-body tw-font-bold tw-text-400 text tw-text-gray-900" i18n="@@checksSkinReport"> Please wait as <br />
          <span>{{allocatedDoctor?.get('DoctorDisplayName')}} checks your skin report and prepares a treatment kit</span>
        </h1>
      </div>
      <div id="showRegimenPageBtn" class="tw-flex-1" *ngIf="showSkinReport && showRegimenToast" (click)="showRegimenPage(true)">
        <h1 class="tw-pb-2 tw-font-body tw-font-bold tw-text-400 text tw-text-gray-900"> Your Treatment Kit is now ready </h1>
        <cs-button class="tw-my-2" [class]="'w100 primary btn-xs btn-padding btn-float'">
          <cs-button-text>
            <span class="tw-font-body tw-tracking-normal">View now</span>
            <span>&nbsp; ></span>
          </cs-button-text>
        </cs-button>
      </div>
    </section>
    <ng-container *ngIf="!showSkinReport">
      <section class="tw-px-6 tw-pt-6 tw-pb-5">
        <div class="tw-pb-1 tw-font-body tw-font-bold tw-text-300 tw-text-gray-900" *ngIf="primaryConcern && appConfig.Shared.Regimen.Class.FACE === regimen.class">
          <span class="tw-font-normal" i18n="@@mainConcern">Main concern:</span>
          <span class="bolder"> {{ primaryConcern }}</span>
          <p class="tw-pb-1 tw-font-body tw-font-bold tw-text-300 tw-text-gray-900" *ngIf="otherConcerns && appConfig.Shared.Regimen.Class.FACE === regimen.class">
            <span class="tw-font-normal" i18n="@@otherConcerns">Other concerns:<span class="bolder">
                {{ otherConcerns }}</span>
            </span>
          </p>
          <p class="tw-pb-1 tw-font-body tw-font-bold tw-text-300 tw-text-gray-900" *ngIf="appConfig.Shared.Regimen.Class.FACE === regimen.class">
            <span class="tw-font-normal">
              <span i18n="@@severity">Severity:</span>
              <span class="bolder">&nbsp;</span>
              <span i18n="@@moderate">Moderate</span>
            </span>
          </p>
        </div>
      </section>
      <section *ngIf="allocatedDoctor" class="tw-mx-6 tw-mb-6 tw-py-4 tw-bg-white tw-rounded-xl tw-shadow-z1 tw-relative tw-overflow-hidden">
        <div class="tw-bg-grid-layout-doctor tw-bg-right tw-bg-repeat-y tw-absolute tw-top-0 tw-bottom-0 tw-left-60 tw-right-[-300px]"></div>
        <div class="tw-z-10 tw-relative">
          <div class="tw-px-6 tw-py-2 tw-mb-3 tw-bg-orange-100 tw-rounded-tr-lg tw-rounded-br-lg tw-w-content tw-flex tw-flex-row tw-items-center tw-gap-2">
            <cs-icon class="tw-w-5 tw-h-5 tw-text-orange-500" [iconName]="'check_circle'"></cs-icon>
            <p id="skinSpec" class="tw-font-body tw-font-bold tw-text-200 tw-text-orange-400" *ngIf="appConfig.Shared.Regimen.Class.FACE === regimen.class" i18n="@@yourSkinSpecialist"> Your skin specialist </p>
            <p id="hairSpec" class="tw-font-body tw-font-bold tw-text-200 tw-text-orange-400" *ngIf="appConfig.Shared.Regimen.Class.HAIR === regimen.class" i18n="@@yourHairSpecialist"> Your hair specialist </p>
          </div>
          <div id="doctorCard" class="tw-flex tw-flex-row tw-items-center tw-gap-1 tw-pl-6 tw-pr-4 tw-justify-between">
            <div>
              <h2 class="tw-font-body tw-font-bold tw-text-500 tw-text-gray-900 tw-mb-1">
                {{allocatedDoctor?.get('DoctorDisplayName')}}
              </h2>
              <p class="tw-font-body tw-text-300 tw-text-gray-400 tw-mb-2">
                {{ conn.getQualifyText(allocatedDoctor) }}
              </p>
              <p class="tw-font-body tw-font-bold tw-text-200 tw-text-gray-900" i18n="@@noOfCureskinPatientTreatedSoFar"> Has treated {{allocatedDoctor?.get('patientsTreated') || 0}}+ <br />Cureskin patients </p>
            </div>
            <img id="docImg" class="tw-flex-none w- tw-h-28 tw-w-28 tw-rounded-full tw-bg-green-100" *ngIf="allocatedDoctor?.get('DoctorImageThumbnail')" [src]="allocatedDoctor.get('DoctorImageThumbnail')" alt="Doctor Image" />
          </div>
        </div>
      </section>
      <section class="tw-mb-4">
        <p class="tw-px-6 tw-font-body tw-text-300 tw-text-gray-900 tw-mb-3" *ngIf="appConfig.Shared.Regimen.Class.FACE === regimen.class" i18n="@@fourStepSkinCareNew"> Given your skin concerns, we are creating a <span class="bolder"> skin treatment kit for you.</span>
          <span> This customized kit contains products with specific ingredients to suit your skin type and needs.</span>
        </p>
        <p class="tw-px-6 tw-font-body tw-text-300 tw-text-gray-900 tw-mb-3" *ngIf="appConfig.Shared.Regimen.Class.HAIR === regimen.class" i18n="@@fourStepHairCareNew"> Given your hair concerns, we are creating a <span class="bolder"> hair treatment kit for you.</span>
          <span> The customized kit contains products with specific ingredients to suit your hair type and needs.</span>
        </p>
        <ul class="tw-flex tw-flex-row tw-justify-between tw-items-start tw-gap-4 tw-px-4" *ngIf="regimen.regimenTag">
          <li class="tw-flex-1 tw-flex tw-flex-col tw-items-center tw-gap-2" *ngFor="let item of regimen.regimenTag">
            <img class="tw-h-16 tw-w-16" [src]="item.rebrandedImage" />
            <p class="tw-font-body tw-text-300 tw-text-gray-900 tw-text-center tw-capitalize">
              {{ item.title }}
            </p>
          </li>
        </ul>
      </section>
      <div class="tw-w-full tw-mb-5 tw-px-4">
        <div class="tw-w-full tw-rounded-lg tw-border-2 tw-border-orange-200 tw-bg-opacity-50 tw-p-5">
          <h1 class="tw-font-medium" i18n="@@reportDisclaimer">This Report will be reviewed by a dermatologist after you order your treatment kit.</h1>
        </div>
      </div>
      <testimonial-wall [regimen]="regimen" [experiments]="experiments"></testimonial-wall>
    </ng-container>
    <report id="skinReport" *ngIf="showSkinReport" [regimen]="regimen" [experiments]="experiments" [isTimerPage]="true" [showRegimenToast]="showRegimenToast || showPreparationTimer" (hideReport)="showSkinReport=false" (showRegimenTimer)="showPreparationTimer = true"></report>
  </div>
  <div *ngIf="showRegimenToast" id="kitReady" class="tw-fixed w100 tw-bottom-0 tw-right-0 tw-flex tw-items-center font-noto tw-justify-evenly tw-bg-green-200 tw-p-2 tw-z-[100]" (click)="showRegimenPage(true)">
    <img class="tw-rounded-full tw-object-cover" style="width: 60px; height: 60px" [src]="regimen?.regimenCoverImage" />
    <div class="tw-text-400 tw-font-bold" i18n="@@reportKitReady">Your treatment kit<br />is ready</div>
    <cs-button class="tw-my-2" [class]="'w100 primary btn-xs btn-padding btn-float'"><cs-button-text><span class="tw-font-body tw-tracking-normal" i18n="@@reportViewNow">View now</span><span>&nbsp; ></span></cs-button-text></cs-button>
  </div>

  <div *ngIf="!showRegimenToast && showPreparationTimer" class="tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-flex tw-flex-col tw-items-center tw-gap-4 tw-bg-yellow-100 tw-px-5 tw-py-7">
    <div class="tw-flex-none tw-relative tw-overflow-hidden tw-h-8 tw-w-full">
      <div class="ticker-animation tw-absolute tw-left-0 tw-right-0 tw-text-center">
        <p class="tw-h-8 tw-leading-8 tw-text-400 tw-text-black tw-font-body" i18n="@@reviewReport">We are reviewing your report</p>
        <p class="tw-h-8 tw-leading-8 tw-text-400 tw-text-black tw-font-body" i18n="@@creatingKit">Creating your treatment kit</p>
      </div>
    </div>
    <div class="tw-flex-auto tw-w-full">
      <div class="tw-flex tw-items-center tw-relative tw-rounded-full tw-h-8 tw-bg-repeat-x tw-bg-contain timer-container-below tw-overflow-hidden" style="background-image: url('assets/images/background-arrow.png');">
        <div  class="tw-rounded-l-full tw-h-full timer-line" [ngStyle]="{'animation-duration': defaultTime + 'ms', 'animation-delay': elapsedTime + 'ms'}" ></div>
        <div class="tw-absolute tw-left-0 tw-right-0 tw-top-0 tw-bottom-0 tw-flex tw-items-center tw-justify-center tw-font-bold tw-font-body tw-text-300">0{{timer.minutes}}:{{timer.seconds < 10 ? '0' + timer.seconds : timer.seconds}}</div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #rebrandedTemplateV1>
  <!-- Rebranded template v1 -->
  <div class="tw-bg-gray-100">
    <section class="tw-bg-purple-200 tw-pt-5 tw-bg-grid-layout tw-bg-repeat-x tw-flex tw-gap-4 tw-items-center tw-px-6 tw-pb-5" style="background-position: bottom -75px right 0" *ngIf="!showSkinReport">
      <div class="tw-flex-none tw-relative tw-h-[148px] tw-w-[148px]">
        <svg class="tw-absolute tw-left-0" width="148" height="148" viewBox="0 0 148 148" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle class="tw-stroke-[#D9D9D9]" cx="50%" cy="50%" r="70px" stroke-width="8" fill="none"></circle>
        </svg>
        <svg class="tw-absolute tw-left-0" width="148" height="148" viewBox="0 0 148 148" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle class="tw-stroke-yellow-400 from_0" cx="50%" cy="50%" r="70px" stroke-width="8" fill="none" stroke-dasharray="440px, 440px" [ngStyle]="{ 'animation-delay': elapsedTime + 'ms', 'animation-duration': defaultTime + 'ms'}"></circle>
        </svg>
        <div class="tw-flex tw-absolute tw-top-[60px] tw-left-[30px]">
          <div class="tw-flex-none">
            <p class="tw-font-body tw-font-bold tw-text-900 tw-leading-[28px]"> 0{{timer.minutes}}
            </p>
            <p class="tw-font-body tw-text-300 tw-text-center">min</p>
          </div>
          <div class="tw-flex-none tw-font-body tw-font-bold tw-text-900 tw-leading-[28px]"> : </div>
          <div class="tw-flex-none">
            <p class="tw-font-body tw-font-bold tw-text-900 tw-leading-[28px]">
              {{timer.seconds < 10 ? '0' + timer.seconds : timer.seconds}}
            </p>
            <p class="tw-font-body tw-text-300 tw-text-center">sec</p>
          </div>
        </div>
      </div>
      <div class="tw-flex-1" *ngIf="!showSkinReport">
        <h1 class="tw-pb-2 tw-font-body tw-font-bold tw-text-400 text tw-text-gray-900" i18n="@@pleaseWaitForTwoMins"> Please wait for a couple of minutes </h1>
        <p class="tw-font-body tw-text-300" i18n="@@effTreatmentPrep"> Based on your analysis, we are preparing the most effective treatment kit for you. </p>
      </div>
      <div class="tw-flex-1" *ngIf="showSkinReport && !showRegimenToast">
        <h1 class="tw-pb-2 tw-font-body tw-font-bold tw-text-400 text tw-text-gray-900" i18n="@@checksSkinReport"> Please wait as <br />
          <span>{{allocatedDoctor?.get('DoctorDisplayName')}} checks your skin report and prepares a treatment kit</span>
        </h1>
      </div>
      <div id="showRegimenPageBtn" class="tw-flex-1" *ngIf="showSkinReport && showRegimenToast" (click)="showRegimenPage(true)">
        <h1 class="tw-pb-2 tw-font-body tw-font-bold tw-text-400 text tw-text-gray-900"> Your Treatment Kit is now ready </h1>
        <cs-button class="tw-my-2" [class]="'w100 primary btn-xs btn-padding btn-float'">
          <cs-button-text>
            <span class="tw-font-body tw-tracking-normal">View now</span>
            <span>&nbsp; ></span>
          </cs-button-text>
        </cs-button>
      </div>
    </section>
    <ng-container *ngIf="!showSkinReport">
      <section class="tw-px-6 tw-pt-6 tw-pb-5">
        <div class="tw-pb-1 tw-font-body tw-font-bold tw-text-300 tw-text-gray-900" *ngIf="primaryConcern && appConfig.Shared.Regimen.Class.FACE === regimen.class">
          <span class="tw-font-normal" i18n="@@mainConcern">Main concern:</span>
          <span class="bolder"> {{ primaryConcern }}</span>
          <p class="tw-pb-1 tw-font-body tw-font-bold tw-text-300 tw-text-gray-900" *ngIf="otherConcerns && appConfig.Shared.Regimen.Class.FACE === regimen.class">
            <span class="tw-font-normal" i18n="@@otherConcerns">Other concerns:<span class="bolder">
                {{ otherConcerns }}</span>
            </span>
          </p>
          <p class="tw-pb-1 tw-font-body tw-font-bold tw-text-300 tw-text-gray-900" *ngIf="appConfig.Shared.Regimen.Class.FACE === regimen.class">
            <span class="tw-font-normal">
              <span i18n="@@severity">Severity:</span>
              <span class="bolder">&nbsp;</span>
              <span i18n="@@moderate">Moderate</span>
            </span>
          </p>
        </div>
      </section>
      <section *ngIf="allocatedDoctor" class="tw-mx-6 tw-mb-6 tw-py-4 tw-bg-white tw-rounded-xl tw-shadow-z1 tw-relative tw-overflow-hidden">
        <div class="tw-bg-grid-layout-doctor tw-bg-right tw-bg-repeat-y tw-absolute tw-top-0 tw-bottom-0 tw-left-60 tw-right-[-300px]"></div>
        <div class="tw-z-10 tw-relative">
          <div class="tw-px-6 tw-py-2 tw-mb-3 tw-bg-orange-100 tw-rounded-tr-lg tw-rounded-br-lg tw-w-content tw-flex tw-flex-row tw-items-center tw-gap-2">
            <cs-icon class="tw-w-5 tw-h-5 tw-text-orange-500" [iconName]="'check_circle'"></cs-icon>
            <p id="skinSpec" class="tw-font-body tw-font-bold tw-text-200 tw-text-orange-400" *ngIf="appConfig.Shared.Regimen.Class.FACE === regimen.class" i18n="@@yourSkinSpecialist"> Your skin specialist </p>
            <p id="hairSpec" class="tw-font-body tw-font-bold tw-text-200 tw-text-orange-400" *ngIf="appConfig.Shared.Regimen.Class.HAIR === regimen.class" i18n="@@yourHairSpecialist"> Your hair specialist </p>
          </div>
          <div id="doctorCard" class="tw-flex tw-flex-row tw-items-center tw-gap-1 tw-pl-6 tw-pr-4 tw-justify-between">
            <div>
              <h2 class="tw-font-body tw-font-bold tw-text-500 tw-text-gray-900 tw-mb-1">
                {{allocatedDoctor?.get('DoctorDisplayName')}}
              </h2>
              <p class="tw-font-body tw-text-300 tw-text-gray-400 tw-mb-2">
                {{ conn.getQualifyText(allocatedDoctor) }}
              </p>
              <p class="tw-font-body tw-font-bold tw-text-200 tw-text-gray-900" i18n="@@noOfCureskinPatientTreatedSoFar"> Has treated {{allocatedDoctor?.get('patientsTreated') || 0}}+ <br />Cureskin patients </p>
            </div>
            <img id="docImg" class="tw-flex-none w- tw-h-28 tw-w-28 tw-rounded-full tw-bg-green-100" *ngIf="allocatedDoctor?.get('DoctorImageThumbnail')" [src]="allocatedDoctor.get('DoctorImageThumbnail')" alt="Doctor Image" />
          </div>
        </div>
      </section>
      <section class="tw-mb-4">
        <p class="tw-px-6 tw-font-body tw-text-300 tw-text-gray-900 tw-mb-3" *ngIf="appConfig.Shared.Regimen.Class.FACE === regimen.class" i18n="@@fourStepSkinCareNew"> Given your skin concerns, we are creating a <span class="bolder"> skin treatment kit for you.</span>
          <span> This customized kit contains products with specific ingredients to suit your skin type and needs.</span>
        </p>
        <p class="tw-px-6 tw-font-body tw-text-300 tw-text-gray-900 tw-mb-3" *ngIf="appConfig.Shared.Regimen.Class.HAIR === regimen.class" i18n="@@fourStepHairCareNew"> Given your hair concerns, we are creating a <span class="bolder"> hair treatment kit for you.</span>
          <span> The customized kit contains products with specific ingredients to suit your hair type and needs.</span>
        </p>
        <ul class="tw-flex tw-flex-row tw-justify-between tw-items-start tw-gap-4 tw-px-4" *ngIf="regimen.regimenTag">
          <li class="tw-flex-1 tw-flex tw-flex-col tw-items-center tw-gap-2" *ngFor="let item of regimen.regimenTag">
            <img class="tw-h-16 tw-w-16" [src]="item.rebrandedImage" />
            <p class="tw-font-body tw-text-300 tw-text-gray-900 tw-text-center tw-capitalize">
              {{ item.title }}
            </p>
          </li>
        </ul>
      </section>
      <div class="tw-w-full tw-mb-5 tw-px-4">
        <div class="tw-w-full tw-rounded-lg tw-border-2 tw-border-orange-200 tw-bg-opacity-50 tw-p-5">
          <h1 class="tw-font-medium" i18n="@@reportDisclaimer">This Report will be reviewed by a dermatologist after you order your treatment kit.</h1>
        </div>
      </div>
      <testimonial-wall [regimen]="regimen" [experiments]="experiments"></testimonial-wall>
    </ng-container>
    <report id="skinReport" *ngIf="showSkinReport" [regimen]="regimen" [experiments]="experiments" [isTimerPage]="true" [showRegimenToast]="showRegimenToast || showPreparationTimer" (hideReport)="showSkinReport=false" (showRegimenTimer)="showPreparationTimer = true"></report>
  </div>
  <div *ngIf="showRegimenToast" id="kitReady" class="tw-fixed w100 tw-bottom-0 tw-right-0 tw-flex tw-items-center font-noto tw-justify-between tw-bg-green-200 tw-px-4 tw-py-2 tw-z-[100] tw-h-20 tw-gap-4" (click)="showRegimenPage(true)">
    <div class="tw-w-15 tw-h-15 tw-shrink-0 tw-bg-gray-50 tw-p-[2px] tw-rounded-full">
      <img class="tw-rounded-full tw-object-cover" style="width: 100%; height: 100%" [src]="regimen?.regimenCoverImage" />
    </div>
    <div class="tw-text-400 tw-text-gray-800 tw-font-bold" i18n="@@yourTreatmentKitIsReady">Your treatment kit is ready</div>
    <cs-button [class]="'w100 primary btn-padding btn-float tw-min-w-unset tw-text-200 tw-font-bold tw-p-0 tw-h-8 tw-w-[108px]'"><cs-button-text><span class="tw-font-body tw-tracking-normal" i18n="@@reportViewNow">View now</span><span>&nbsp; ></span></cs-button-text></cs-button>
  </div>

  <div *ngIf="!showRegimenToast && showPreparationTimer" class="tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-flex tw-flex-col tw-items-center tw-bg-yellow-100 tw-px-10 tw-pt-2 tw-pb-4 tw-z-[100] tw-h-20">
    <div class="tw-flex-none tw-relative tw-overflow-hidden tw-h-8 tw-w-full">
      <div class="ticker-animation tw-absolute tw-left-0 tw-right-0 tw-text-center">
        <p class="tw-h-8 tw-text-300 tw-text-gray-800 tw-font-body" i18n="@@reviewReport">We are reviewing your report</p>
        <p class="tw-h-8 tw-text-300 tw-text-gray-800 tw-font-body" i18n="@@creatingKit">Creating your treatment kit</p>
      </div>
    </div>
    <div class="tw-flex-auto tw-w-full">
      <div class="tw-flex tw-items-center tw-relative tw-rounded-full tw-h-6 tw-bg-repeat-x tw-bg-contain timer-container-below tw-overflow-hidden" style="background-image: url('assets/images/background-arrow.png');">
        <div  class="tw-rounded-l-full tw-h-full timer-line" [ngStyle]="{'animation-duration': defaultTime + 'ms', 'animation-delay': elapsedTime + 'ms'}" ></div>
        <div class="tw-absolute tw-left-0 tw-right-0 tw-top-0 tw-bottom-0 tw-flex tw-items-center tw-justify-center tw-font-bold tw-font-body tw-text-200 tw-text-gray-800">0{{timer.minutes}}:{{timer.seconds < 10 ? '0' + timer.seconds : timer.seconds}}</div>
      </div>
    </div>
  </div>
</ng-template>
