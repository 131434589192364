<section class="tw-bg-blue-100 tw-pb-2 tw-p-5 tw-mx-4 tw-border tw-border-gray-200 tw-rounded-xl tw-overflow-hidden tw-mb-6 tw-mt-4" style="background: white !important">
    <div class="tw-flex tw-gap-6 tw-mb-4" *ngIf="productsInCart?.isDoctorAdded">
        <span>
            <img alt="icon" src="/assets/icons/orange_tick_new.svg" class="tw-absolute tw-left-[70px]" id="tick">
            <img alt="doctor" class="tw-flex-none tw-h-12 tw-w-12" [src]="userDrImage" />
        </span>
        <span *ngIf="user?.get('languagePreference') !== 'hi'">
            <h2 class="tw-font-body tw-font-bold tw-text-500 tw-text-black tw-tracking-normal">
                Doctor added product
            </h2>
            <p class="tw-font-body tw-text-300 tw-text-gray-500">
                basis your treatment
            </p>
        </span>
        <span *ngIf="user?.get('languagePreference') === 'hi'">
            <h2 class="tw-font-body tw-font-bold tw-text-500 tw-text-black tw-tracking-normal" i18n="@@drAddedProdTreatment">
                Doctor added product basis your treatment
            </h2>
        </span>
    </div>
    <div class="tw-mb-4" *ngIf="productsInCart?.scriptAudioUrl">
        <c-audio class="c-audio" src="{{productsInCart?.scriptAudioUrl}}" [trackEvent]="trackEventAudio" eventPrefix="OrderCreatedDoctor" (emitTrackEvent)="handleClickOne($event)"></c-audio>
    </div>
    <div class="tw-flex tw-bg-white tw-rounded-2 tw-border-2 tw-border-gray-100 tw-mb-4 tw-relative tw-overflow-hidden" (click)="handleClick()">
          <div class="tw-flex-none tw-flex tw-justify-center tw-items-center tw-box-content tw-w-2/5">
            <img alt="product" class="tw-max-w-full tw-max-h-full tw-min-w-full tw-min-h-full tw-rounded-tr-2 tw-rounded-br-2" [src]="productsInCart?.rebrandedImageWithBackground || productsInCart?.productUnboxedImage || productsInCart?.rebrandedImageWithoutBackground" />
            <div class="tw-absolute tw-bottom-0 tw-left-0 tw-w-[40%] tw-bg-gray-800 tw-text-center tw-gap-2 tw-bg-orange-100 tw-flex tw-items-center tw-justify-center" id="dr-added">
                <cs-icon class="tw-h-3 tw-w-3 tw-text-bold tw-text-orange-400" [iconName]='"done"'></cs-icon>
                    <p class="tw-font-body tw-text-orange-400 tw-text-200 tw-whitespace-nowrap" i18n="@@drAdded">
                      Dr. Added
                    </p>
            </div>
        </div>
          <div class="tw-px-4 tw-flex tw-flex-col tw-items-start tw-gap-2 tw-relative tw-pb-2 tw-pt-5" (click)="handleClick()">
            <p class="tw-font-body tw-text-300 tw-text-black tw-tracking-normal" id="product-title">
                {{productsInCart?.title}}
            </p>
            <p class="tw-font-body tw-text-300 tw-tracking-normal tw-text-black">
                <span class="tw-line-through">&ngsp;&ngsp; ₹{{productsInCart?.mrp}}&ngsp;</span><span class="tw-font-bold tw-text-orange-400">₹{{productsInCart?.price}}</span>
            </p>
            <button class="tw-m-0 tw-min-w-unset tw-h-10 tw-px-4 tw-rounded-3 tw-flex tw-items-center tw-gap-2 tw-bg-teal-600" (click)="handleClick()">
                <span class="tw-normal-case tw-font-body tw-text-300 tw-tracking-normal tw-text-white tw-whitespace-break-spaces" i18n="@@buyNow">Buy Now</span>
            </button>
        </div>
    </div>
  </section>
  